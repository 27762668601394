<template>
    <div>

        <!-- Header-Image Section -->
        <section class="header-image">

            <div class="container text-center">
                <h1 class="text-white">PharmaConnect | Actualités et Nouveautés</h1>
            </div>

        </section>

        <!-- Actuality Section -->
        <section class="blog bg-light-gray">
            <div class="container">
                <h2 class="text-center actuality"></h2>
                <div class="row">
                    <div class="col-md-4" v-for="blog in blogs" :key="blog.id">
                        <div class="card">
                            <img v-if="blog.image == undefined || blog.image == null || blog.image == ''"
                                :src="require('@/assets/images/website/400x200.png')" class="card-img-top"
                                alt="Blog Image">
                            <img v-else :src="$serverpath + blog.image" class="card-img-top" alt="Blog Image"
                                height="200" width="400">
                            <div class="card-body">
                                <h5 class="card-title">{{ blog.title }}</h5>
                                <p class="card-text">{{ blog.description }}</p>
                                <div class="d-flex justify-content-between align-items-center">
                                    <router-link v-if="blog.id" :to="'/news/' + blog.id" class="btn btn-primary">Voir
                                        Plus</router-link>
                                    <p class="card-text mb-0"><small class="text-muted">{{ blog.createdAt }}</small></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <hr>

        <!-- Pagination -->
        <section>
            <b-row>
                <b-col cols="12">
                    <b-pagination v-model="currentPgae" :total-rows="totalElements" :per-page="perPage" first-number
                        align="center" last-number prev-class="prev-item" next-class="next-item">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </b-col>
            </b-row>
        </section>

    </div>

</template>

<script>


export default {
    metaInfo() {
        return {
            title: 'PharmaConnect | Actualités et Nouveautés',
            meta: [
                {
                    vmid: "description",
                    name: "description",
                    content: "Restez informé des dernières actualités et nouveautés dans le domaine pharmaceutique en Tunisie grâce à PharmaConnect."
                },
                {
                    name: 'keywords',
                    content: 'PharmaConnect, Pharmacie, Grossistes, Fournisseurs, Tunisie, Pharmaciens, Plateforme Pharmaceutique, parapharmacie,parapharmaceutique, Commandes en Ligne'
                },
                {
                    name: 'author',
                    content: 'DIGITHINK CONSULTING'
                },
                {
                    property: 'og:title',
                    content: 'PharmaConnect | Actualités et Nouveautés'
                },
                {
                    property: 'og:description',
                    content: "Restez informé des dernières actualités et nouveautés dans le domaine pharmaceutique en Tunisie grâce à PharmaConnect. Consultez les circulaires, les annances et les nouveautés du secteur directement sur laplateforme."
                },
                {
                    name: "robots",
                    content: "index, follow",
                },
                {
                    property: "og:image",
                    content: require('@/assets/images/logo/logo.svg'),
                },
                {
                    property: "og:url",
                    content: "https://www.pharmaconnect.net/news",
                },
                {
                    property: "og:type",
                    content: "website",
                },
                {
                    name: "twitter:card",
                    content: "summary_large_image",
                },
                {
                    name: "twitter:title",
                    content: "PharmaConnect | Actualités et Nouveautés",
                },
                {
                    name: "twitter:description",
                    content: "Restez informé des dernières actualités et nouveautés dans le domaine pharmaceutique en Tunisie grâce à PharmaConnect. Consultez les circulaires, les annances et les nouveautés du secteur directement sur laplateforme."
                },
                {
                    name: "twitter:image",
                    content: require('@/assets/images/logo/logo.svg'),
                },
            ],
            link: [
                {
                    rel: "canonical",
                    href: "https://www.pharmaconnect.net/news",
                },
            ],
            script: [
                {
                    type: "application/ld+json",
                    json: {
                        "@context": "https://schema.org",
                        "@type": "WebPage",
                        "name": "PharmaConnect | Actualités et Nouveautés",
                        "description": "Restez informé des dernières actualités et nouveautés dans le domaine pharmaceutique en Tunisie grâce à PharmaConnect. Consultez les circulaires, les annances et les nouveautés du secteur directement sur laplateforme.",
                        "brand": {
                            "@type": "Brand",
                            "name": "PharmaConnect"
                        }
                    },
                },
            ],
        };
    },
    data() {
        return {
            currentPgae: 1,
            perPage: 3,
            totalElements: 0,
            blogs: [
                { image: '', title: '', description: '' },
                { image: '', title: '', description: '' },
                { image: '', title: '', description: '' }
            ],
        };
    },
    methods: {
        async loadBlogs() {
            try {
                let response = await this.$http.get("blog/anonymous", { params: { page: this.currentPgae - 1, size: this.perPage } });
                this.blogs = response.data.content;
                this.totalElements = response.data.totalElements;
            } catch (error) {
                console.log(error)
            }
        }
    },
    watch: {
        currentPgae() {
            this.loadBlogs();
        }
    },
    created() {
        this.loadBlogs();
    }
};
</script>

<style scoped>
.header-image {
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('~@/assets/images/website/page-header-bg.jpg') no-repeat center center;
    background-size: cover;
    color: white;
    padding: 50px 0;
}

.actuality {
    padding: 50px;
}
</style>